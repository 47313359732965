import { isBefore, addMinutes, isSameHour, isSameMinute, isAfter, isWithinInterval } from 'date-fns';

export default {
    data: () => ({
        appointmentStatuses: [
            { id: 11, label: 'Annulation par l\'acquéreur', color: 'red lighten-2', textColor: 'red--text text--lighten-2' },
            { id: 12, label: 'Annulation par le vendeur', color: 'red lighten-2', textColor: 'red--text text--lighten-2' },
            { id: 255, label: 'Réalisé', color: 'green lighten-2', textColor: 'green--text text--lighten-2' },
            { id: 0, label: 'Planifié', color: 'blue ligthen-2', textColor: 'blue--text text--ligthen-2' },
            { id: null, label: 'Non programmé', color: 'grey ligthen-2', textColor: 'grey--text text--ligthen-2' }
        ]
    }),

    methods: {
        getAppointmentStatusColor(id) {
            let item = this.appointmentStatuses.find((o) => o.id === id);
            if (!item) {
                item = this.appointmentStatuses.find((o) => o.id === 0);
            }
            return item.color;
        },
        getAppointmentStatusLabel(id) {
            let item = this.appointmentStatuses.find((o) => o.id === id);
            if (!item) {
                item = this.appointmentStatuses.find((o) => o.id === 0);
            }
            return item.label;
        },
        getAppointmentStatusTextColor(id) {
            let item = this.appointmentStatuses.find((o) => o.id === id);
            if (!item) {
                item = this.appointmentStatuses.find((o) => o.id === 0);
            }
            return item.textColor;
        },

        getDisponibilityHours(appointment, instances, disponibility) {
            const hours = [];

            const increment = 30;
            let totalIncrement = 0;

            const startDateOfDisponibility = new Date(disponibility.startDate);
            const endDateOfDisponibility = new Date(disponibility.endDate);

            const keepGoing = true;
            let max = 0;

            while (keepGoing) {
                const candidateStartHour = addMinutes(startDateOfDisponibility, totalIncrement);
                const candidateEndHour = addMinutes(candidateStartHour, appointment.duration || 30);
                totalIncrement += increment;

                // Est-ce que l'heure de fin de l'heure proposée dépasse l'heure de fin de la disponibilité ?
                if (isAfter(candidateEndHour, endDateOfDisponibility)) {
                    break;
                }

                if (isBefore(candidateEndHour, new Date())) {
                    continue;
                }

                // Est-ce que l'heure de fin proposée empiéte sur un rendez-vous déjà fixé
                const endCheck = instances.some((instance) => {
                    // const isAfterCheck = isAfter(candidateEndHour, new Date(instance.startDate));
                    // const isAfterSameHourCheck = isSameHour(candidateEndHour, new Date(instance.startDate));
                    // const isBeforeCheck = isBefore(candidateEndHour, new Date(instance.endDate));
                    // const isBeforeCheck = isBefore(candidateEndHour, new Date(instance.endDate));
                    return isWithinInterval(candidateEndHour, { start: new Date(instance.startDate), end: new Date(instance.endDate) });
                });
                if (endCheck) {
                    continue;
                }

                // Est-ce que l'heure de début proposée empiéte sur un rendez-vous déjà fixé
                const startCheck = instances.some((instance) => {
                    return isAfter(candidateStartHour, new Date(instance.startDate)) && isBefore(candidateStartHour, new Date(instance.endDate))
                        && !(isSameHour(candidateStartHour, new Date(instance.endDate)) && isSameMinute(candidateStartHour, new Date(instance.endDate)));
                });
                if (startCheck) {
                    continue;
                }

                hours.push(candidateStartHour);

                max++;
                if (max >= 1000) {
                    console.error('infinite loop');
                    break;
                }
            }

            return hours;
        }
    }
};
